@import '/src/theme/main.scss';
@keyframes HandWave {
  to {
    transform: rotate(25deg);
  }
}
.mainPro {
  justify-content: space-evenly;
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  box-shadow: inset;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  margin: 0px 30px 30px;
}

.CenterB {
  margin-right: 25px;
  text-align: center;
}

.colorBox {
  width: 110px;
  padding: 5px;
  margin: 5px;
}

.txtCol {
  color: black;
  font-weight: 500;
  font-size: large;
}

.about-me {
}

.wrapper {
  padding: 20px;
  border-radius: 25px;
}

.editor-wrapper {
  direction: ltr;
}

.editor-main {
  min-height: 50vmin !important;
}

.myColo {
  background-color: rgb(220, 230, 255);
  padding-right: 20px;
  padding-left: 20px;
}

.welcomebox {
  text-align: center;
  @media (min-width: 600px) {
    position: sticky;
    top: 80px;
    width: 330px;
  }

  font-size: x-large;
  font-weight: 700;
  &__display-name {
    display: flex;
    height: 70px;
    flex-direction: row;
    padding: 5px;
    @media (min-width: 600px) {
      margin: 0px 20px 20px;
    }
    @media (max-width: 600px) {
      margin: 0px 0px;
    }
    img {
      margin: auto;
      width: 45px;
      height: 45px;
      text-align: left;
      margin-inline-start: 8%;
      border-radius: 10%;
    }
    &__content {
      margin: auto;
      text-align: center;
      margin-inline-start: 0%;
      border-radius: 10%;
    }
  }

  &__Hello {
    height: 440px;
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    @media (min-width: 600px) {
      margin: 20px;
    }
    @media (max-width: 600px) {
      margin: 20px 0px;
    }
    img {
      width: 250px;
      margin: 5px;
      border-radius: 10%;
    }
  }
  &__color-modal {
    margin: 20px;
  }
}
.marginBox {
  @media (min-width: 600px) {
    margin-left: 15px;
    margin-right: 8px;
  }
}

.student-profile {
  &__color-modal {
    background-image: url(../../assets/images/color_papers.jpg);
    opacity: 0.7;
    color: whitesmoke;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    &__submit-btn {
      display: flex;
      text-align: center;
      background: radial-gradient(#ffffff, color(student-primary-light));
      width: 100%;
      height: 50px;
      justify-content: center;
      border-top: 3px dotted color(student-primary);
      a {
        color: color(student-primary);
        width: 100%;
      }
    }
  }
}
