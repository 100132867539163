@import '/src/theme/main.scss';

.forget-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: justify;
}

.forget-password-page-button {
  background-color: rgb(99, 36, 200) !important;
}

.forget-password-avatar {
  background-color: rgb(99, 36, 200) !important;
}

// .Toastify__toast--error {
//   background: rgb(49, 3, 102) !important;
// }

.Toastify__toast--success {
  background: rgb(4, 61, 61) !important;
}
.loading-forget-password {
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
