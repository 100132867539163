@import '/src/theme/main.scss';

.class-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgb(221, 223, 227);

  .class-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 4px 24px #eee;
    cursor: pointer;
  }

  &__content {
    flex-grow: 1;
    padding: 0 24px;
  }

  &__img-wrapper {
    min-width: 240px;
    min-height: 150px;
    border-radius: 8px;
  }

  &__img {
    width: 240px;
    height: 150px;
    border-radius: 8px;
  }

  &__age {
    color: rgb(106, 116, 130);
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__title {
    color: rgb(46, 50, 56);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__description {
    color: rgb(46, 50, 56);
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__teacher-rating-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .class-teacher-card {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &__img-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    &__img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    &__name {
      margin-right: 8px;
      color: #333;
      font-size: 14px;
    }
  }

  &__rating {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  &__date-price-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__date {
    display: flex;
    align-items: center;
    color: rgb(106, 116, 130);
    font-size: 16px;
  }

  &__price {
    display: flex;
    align-items: center;
    color: rgb(106, 116, 130);
    font-size: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .class-card-wrapper {
      flex-direction: column;
    }

    &__content {
      padding: 0;
    }

    &__img-wrapper {
      width: 100%;
      height: auto;
      background-color: unset;
      max-height: 300px;
      border-radius: 8px;
    }

    &__img {
      width: 100%;
      height: auto;
      max-height: 300px;
      border-radius: 8px;
    }

    &__date-price-wrapper {
      flex-direction: column;
    }

    &__price {
      margin-top: 8px;
      margin-right: 4px;
    }
  }
}

.filters-page {
  margin-top: 64px;
  &__title {
    padding: 24px;
    border-bottom: 1px solid #eee;
    font-size: 24px;
  }
  .show-more-classes-btn-wrapper {
    margin-top: 16px;
    text-align: center;
  }
}

.filters {
  position: sticky;
  top: 60px;
  border-bottom: 1px solid #eee;
  z-index: 100;
  padding: 16px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__search-wrapper {
    display: flex;
    overflow: hidden;
    align-items: center;
    background-color: #fff;
    display: flex;
    padding: 0;
    // width: 100%;
    flex-grow: 1;
    margin-left: 16px;
    // max-width: 1024px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  &__search-input {
    padding: 12px;
    font-size: 16px;
    border: none;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #888;
    }
  }

  &__search-icon {
    cursor: pointer;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__other-filters {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: 1024px;
  }

  &__filter-wrapper {
    flex-grow: 1;
    margin: 16px 8px;
    margin-bottom: 0;
    &:first-of-type {
      margin-right: 0;
    }

    &:last-of-type {
      margin-left: 0;
    }
  }

  &__filter {
    padding: 12px;
    background-color: #fff;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #888;
  }

  &__subjects {
    display: flex;
    align-items: center;
    margin-top: 8px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__subject {
    border: 1px solid rgb(203, 233, 242);
    color: rgb(43, 167, 206);
    background-color: rgba(234, 246, 250, 0.75);
    padding: 8px 16px;
    margin: 8px 4px 0;
    cursor: pointer;
    border-radius: 32px;
    font-weight: 500;
    &:hover {
      background-color: rgba(225, 234, 236, 0.77);
    }
    &.active {
      background-color: #255dad;
      color: #fff;
      border-color: rgba(37, 93, 173, 0.25);
    }
  }
  &__weekdays {
    display: flex;
    margin: 10px;
  }
  &__weekday {
    text-align: center;
    padding: 8px 0px;
    border: 1px solid rgb(203, 233, 242);
    color: rgb(43, 167, 206);
    background-color: rgba(234, 246, 250, 0.75);
    margin: 3px;
    cursor: pointer;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    &:hover {
      background-color: rgba(225, 234, 236, 0.77);
    }
    &.active {
      background-color: #255dad;
      color: #fff;
      border-color: rgba(37, 93, 173, 0.25);
    }
  }
  @media (max-width: 768px) {
    .filters {
      padding: 8px;
    }

    &__subjects {
      justify-content: flex-start;
      margin-bottom: 24px;
    }
  }
}
