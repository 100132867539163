@import '/src/theme/main.scss';

.course-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
      max-width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, $c-gray-95, $color-highlight, $c-gray-95);
    animation: skeleton-loader-progress 1s linear forwards infinite;
    will-change: transform;
    opacity: 0.1;
  }
}
@keyframes skeleton-loader-progress {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(50%, 0, 0);
    }
  }
