@import '/src/theme/main.scss';
.back {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-around;
  align-items: center;
  background-color: #fbdbff;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%233a1092' fill-opacity='0.48'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  @media (max-width: 800px) {
    flex-direction:column;
    min-height: 100vh;
  }
}
.NotF {
  border-radius: 4%;
  background-color: rgb(207, 198, 245);
  margin: 16px;
  margin-top: 63px;
  text-align: center;
  width: 50%;
  box-shadow: rgba(1, 21, 22, 0.5) 0px 2px 10px;
  @media (max-width: 800px) {
    width: 80%;
    height: 50%;
  }
  &__Media {
    border-radius: 4%;
    margin-top: 25px;
    width: 93%;
    text-align: center;
    box-shadow: rgba(1, 21, 22, 0.5) 0px 2px 10px;
    @media (max-width: 800px) {
      width: 85%;
    }
  }
  &__Content {
    font-weight: 700;
    text-align: center;
    padding: 35px;
    color: rgb(89, 18, 122);
    @media (max-width: 800px) {
      font-weight: 400;
    }
  }
}
.ComebackHome {
  align-self: flex-end;
  font-weight: 700;
  font-size: large;
  display: flex;
  padding-top: 25px;
  flex-direction: row;
  margin: 30px;
  &__Media {
    width: 200px;
  }
  &__Content {
    line-height: normal;
    font-weight: 700;
    top: 70px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    height: 60px;
    margin: 2px;
    padding: 15px;
    background-color: rgb(191, 182, 233);
    @media (max-width: 800px) {
      font-weight: 400;
    }
  }
}
