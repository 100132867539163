@import '/src/theme/main.scss';

.best-teachers {
  margin-bottom: 96px;
  text-align: center;

  .swiper-container {
    padding: 10px 0;
  }

  &__title {
    font-size: 24px;
    font-weight: 800;
    color: $c-black;
    margin-bottom: 32px;
  }

  &__highlight {
    color: #ff979d;
  }

  .swiper-slide {
    margin: 10px;
    padding: 10px 0;
    width: 96px;
  }

  &__teacher-img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    box-shadow: 0 0 20px $c-gray-50;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
