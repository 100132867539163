@import '/src/theme/main.scss';
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}
.my-footer {
  padding: 5px;
  text-align: left;
  bottom: 0%;
  width: 25%;
  color: #fff;
  @media (max-width: 600px) {
    width: 80%;
  }
  &__phoneNum {
    font-size: medium;
    font-weight: bold;
  }
  &__media {
    padding: 20px;
    width: 60%;
    text-align: center;
  }
  &__bylove {
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  &__content {
    padding: 20px;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 18px;
      font-weight: 700;
    }
    &__s {
      bottom: auto;
      font-size: medium;
      font-weight: bold;
      text-align: center;
    }
    &__h {
      color: rgb(231, 3, 3);
      padding: 8px;
      bottom: auto;
      font-size: x-large;
      animation: beat 0.25s infinite alternate;
    }
  }
}
.my-social {
  padding: 15px;
  color: #fff;
  @media (max-width: 600px) {
    width: 80%;
  }
  &__Icon {
    width: 64px;
    cursor: pointer;
    padding: 8px;
    &:hover {
      transform: scale(1.15);
    }
    @media (max-width: 600px) {
      width: 55px;
      padding: 6px;
    }
  }
}
.appbarWrapperforfooter {
  overflow: hidden;
  width: '100%';
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: rgba(80, 0, 100, 0.5) 0px 2px 10px;
}
