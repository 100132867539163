@import '/src/theme/main.scss';

.student-info-form__close-icon {
  color: red;
  border: 2px solid red;
  border-radius: 5px;
}

.student-info-form__close-icon:hover {
  color: rgb(126, 12, 12);
  cursor: pointer;
  border: 2px solid rgb(190, 13, 13);
  border-radius: 5px;
}

.student-info-table-container {
  padding-right: 20px !important;
  padding-left: 10px !important;
}

.student-info-table {
  box-shadow: 3px 3px 10px 1px;
  margin: 10px;
  //   padding: 10px !important;
}

.course-student-info-table__image-holder:hover {
  cursor: pointer;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.course-student-info-table__image-holder.muirtl-1ozjs9z-MuiTableCell-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
