.charts{
  width: 100%;
  text-align: center;
  margin: 20px;
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}
.wid{
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  @media (min-width: 800px) {
    width: 60%;
    margin-top: 30px;
  }
}
.wid2{
  font-size: large;
  font-weight: 800;
  color:darkslategrey;
  margin: 10px;
  margin-right: 40px;
  margin-top: 20px;
  width: 20%;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 80%;
    flex-direction: row;
  }
  &__media{
    margin-top: 40px;
    width: 100%;
    @media (max-width: 800px) {
      margin-top: 0px;
      width: 15%;
    }
  }
}
