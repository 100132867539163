@import '/src/theme/main.scss';

.why-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 96px;

  &__title {
    font-size: 24px;
    font-weight: 800;
    color: $c-black;
    padding: 8px;
    margin-bottom: 16px;
  }

  &__highlight {
    color: #ff979d;
  }
}

.appbarWrapper {
  width: '80%';
  display: flex;
  text-align: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.why-card {
  padding: 16px;
  width: calc((100vw - 128px) / 3);
  max-width: 350px;
  display: block;
  margin: 16px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 600px) {
    width: 350px;
    max-width: calc(100vw - 32px);
  }
  &__img {
    padding: 16px;
    width: 85%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  &__title {
    font-size: larger;
    color: indianred;
    text-align: center;
    font-weight: bolder;
  }
  &__content {
    padding: 16px;
    font-size: medium;
    font-weight: bold;
    text-align: center;
    bottom: 30px;
  }
}
