@import '../../theme/main.scss';
.mgRight{
    margin-left: 20px;
    @media (max-width: 800px) {
        display: none;
        color: rgb(165, 247, 226);
    }
}
.myList{
    position: relative;
    width: 90%;
}
.main{
    position: absolute;
    text-align: center;
}
.doNotShow{
    @media (max-width: 800px) {
        display: none;
    }
}
.chartIn{
    @media (max-width: 800px) {
        width: 95%;
    }
}

