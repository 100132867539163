@import '/src/theme/main.scss';

.home-banner {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  margin-bottom: 96px;

  &__img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }

  &__title {
    font-size: 48px;
    font-weight: 800;
    padding: 16px;
  }

  &__description {
    font-size: 24px;
    font-weight: 700;
    padding: 0 24px;
    color: #e9e6dc;
  }

  &__start-btn {
    font-family: 'iranyekan';
    height: 56px;
    width: 256px;
    color: #fff;
    padding: 16px;
    font-size: 18px;
    font-weight: 800;
    border-radius: 32px;
    box-shadow: 0 0 0 6px rgba(253, 87, 108, 0.2);
    border: none;
    background-color: #fd576c;
    margin-top: 32px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 6px rgba(253, 87, 108, 0.5);
    }

    &-highlight {
      color: #000;
    }
  }
}
