@import '/src/theme/main.scss';
.afterMyC-b {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-top: 338px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  text-align: center;
  box-shadow: color(student-primary) 0px 2px 5px;
  background-color: color(student-secondary);
  /*background-color: #feffd1;*/
  overflow-y: auto;
  @media (max-width: 800px) {
    margin-top: 370px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
.ccImg {
  width: 32px;
  margin-top: 301px;
  margin-right: 38%;
  height: 30px;
  position: absolute;
  @media (max-width: 800px) {
    margin-right: 37%;
  }
  @media (max-width: 600px) {
    width: 28px;
    margin-right: 35%;
    margin-top: 298px;
  }
}
.FoxP {
  margin-top: 20px;
  height: 60px;
  // width: fit-content;
  @media (max-width: 800px) {
    margin-top: 30px;
  }
}
.AcDiv {
  margin-top: 10px;
  margin-bottom: 10px;
}
.Text {
  margin-right: 20px;
  margin-top: 28px;
  margin-bottom: 28px;
  width: 80%;
  display: flex;
  flex-direction: row;
}
.Height {
  height: 100px;
}
.rangi {
  font-weight: 800;
  border-radius: 1%;
  border: color(student-secondary);
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  background-color: color(student-primary-light);

}
.firstBt {
  margin-inline-start: 15%;
  @media (max-width: 1000px) {
    margin-inline-start: 7%;
  }
  @media (max-width: 800px) {
    margin-inline-start: 6%;
  }
  @media (max-width: 600px) {
    margin-inline-start: 1%;
  }
}
.SecBt {
  margin-inline-start: 45%;
  @media (max-width: 1000px) {
    margin-inline-start: 43%;
  }
  @media (max-width: 800px) {
    margin-inline-start: 27%;
  }
  @media (max-width: 600px) {
    margin-inline-start: 21%;
  }
}
.BtRow {
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.Mycolor {
  color: color(student-primary);
}

div.assignment-question {
  text-align: right;
  direction: rtl;
  unicode-bidi: bidi-override;
  margin: 10px;
}
div.assignment-question > * > img {
  max-height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.assignment-margin{
  @media (min-width: 800px) {
    margin: auto 80px;
  }
  @media (min-width: 1000px) {
    margin: auto 100px;
  }
}
.NoAssign{
  font-weight: 700;
  font-size: large;
  color: color(student-primary);
}