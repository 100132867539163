@import '../../theme/main.scss';
.BankCard {
  justify-content: center;
  background-attachment: fixed;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  padding: 8px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px !important;
  border-radius: 10px;
  box-shadow: rgb(42, 31, 75) 0px 2px 10px;
  background-color: rgb(16, 97, 100);
}
.CardNumber {
  font-weight: 800;
  font-size: large;
  color: rgb(6, 7, 58);
}
.CardName {

  font-weight: 700 !important;
  color: rgb(207, 207, 207) !important;
  margin-top: 1vmin !important;
  width: 220px !important;
}

.credit-card-input {
  padding: 5px;
  height: 60px !important;
  background-color: transparent;
  border: none;
  color: white;

  @media (max-width: 2500px) {
    font-size: 25px !important;
    width: 90px !important;
    margin-bottom: 2.5vmin !important;
    margin-top: 40px !important;
  }

  @media (max-width: 1500px) {
    font-size: 25px !important;
    width: 80px !important;
    margin-top: 50px !important;
  }

  @media (max-width: 1200px) {
    font-size: 25px !important;
    width: 80px !important;
    margin-bottom: 20px !important;
    margin-top: 50px !important;
  }

  @media (max-width: 900px) {
    font-size: 20px !important;
    width: 65px !important;
    margin-bottom: 16px !important;
    margin-top: 20px !important;
  }

  @media (max-width: 700px) {
    font-size: 20px !important;
    width: 70px !important;
    margin-bottom: 8px !important;
    margin-top: 20px !important;
  }

  @media (max-width: 600px) {
    font-size: 20px !important;
    width: 70px !important;
    margin-bottom: 10px !important;
    margin-top: 30px !important;
  }

  @media (max-width: 460px) {
    font-size: 22px !important;
    width: 70px !important;
    margin-bottom: 20px !important;
    margin-top: 25px !important;
  }

  @media (max-width: 410px) {
    font-size: 18px !important;
    width: 60px !important;
    margin-bottom: 8px !important;
    margin-top: 15px !important;
  }


  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: rgb(201, 193, 193);
  }
}

.shaba {
  padding: 5px !important;
  height: 40px !important;
  background-color: transparent;
  border: none;
  color: white;


  @media (max-width: 2500px) {
    font-size: 13px !important;
    width: 30vmin !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 1500px) {
    font-size: 13px !important;
    width: 260px !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 1200px) {
    font-size: 13px !important;
    width: 260px !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 900px) {
    font-size: 12px !important;
    width: 232px !important;
    letter-spacing: 2.2px !important;
  }

  @media (max-width: 700px) {
    font-size: 12px !important;
    width: 225px !important;
    letter-spacing: 2px !important;
  }

  @media (max-width: 600px) {
    font-size: 2.3vmin !important;
    width: 43vmin !important;
    letter-spacing: 2px !important;
  }

  @media (max-width: 460px) {
    font-size: 2.3vmin !important;
    width: 44vmin !important;
    letter-spacing: 1.8px !important;
  }

  @media (max-width: 380px) {
    font-size: 2.3vmin !important;
    width: 43vmin !important;
    letter-spacing: 1.5px !important;
  }

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: rgb(201, 193, 193);
    font-weight: 1000px !important;
    letter-spacing: 5px !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    @media (min-width: 900px) {
      letter-spacing: 3.5px !important;

    }
  }
}

.credit-card-submit {
  background-color: rgb(26, 128, 97) !important;
}
