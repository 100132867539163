@import '/src/theme/main.scss';

.step-two-dynamic-input-title {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}

.step-two-dynamic-input-fields {
  margin-top: 1.5rem !important;
  margin-right: 0rem !important;
}

.step-two-dynamic-input-buttons {
  margin-top: 5px !important;
}

.create-loading {
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
