.animated-logo {
  display: flex;
  justify-content: center;
  z-index: 20;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
    color: #35326a;
    text-transform: uppercase;
    background-color: #fff;
    width: 24px;
    height: 24px;
    margin: 2px;
    border-radius: 50%;

    &:nth-child(1) {
      animation: drop-letters 5s 2.1s infinite;
    }

    &:nth-child(2) {
      animation: drop-letters 5s 2.2s infinite;
    }

    &:nth-child(3) {
      animation: drop-letters 5s 2.3s infinite;
    }

    &:nth-child(4) {
      animation: drop-letters 5s 2.4s infinite;
    }

    &:nth-child(5) {
      animation: drop-letters 5s 2.5s infinite;
    }

    &:nth-child(6) {
      animation: drop-letters 5s 2.6s infinite;
    }

    &:nth-child(7) {
      animation: drop-letters 5s 2.7s infinite;
    }
    &:nth-child(8) {
      animation: drop-letters 5s 2.8s infinite;
    }
    &:nth-child(9) {
      animation: drop-letters 5s 2.9s infinite;
    }
    &:nth-child(10) {
      animation: drop-letters 5s 3s infinite;
    }
    &:nth-child(11) {
      animation: drop-letters 5s 3.1s infinite;
    }
  }

  @keyframes drop-letters {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(0);
    }
    15% {
      transform: translateY(-100%);
    }
    20% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}
